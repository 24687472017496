import React from 'react'
import { Field, FastField } from 'formik'

import Box from 'components/Box'
import Flex from 'components/Flex'
import Dropdown from 'components/Dropdown'
import { responsive } from 'components/ThemeProvider/theme';

import Data from 'contexts/data'

const AreaDropdown = ({ onChange, onBlur, value, w, label, name, ...props }) => {
  return (
    <Data path="org" loadingSize="2em" width={1}>
      {(d) => {
        // console.log(d)
        if (!d) return null
        const areaData = d.filter(d => d.lev)
          .map(({ shortName, id }) => ({ label: shortName, value: id }))
        return (
          <Flex
            alignItems="center"
            position="relative"
            pt={responsive('1em', 0)}
            width={1}
            as="label"
            {...props}
          >
            <Box
              fontSize="1.125em"
              color="darkPurple"
              pr={responsive('1rem', '1.625rem')}
              fontWeight="bold"
            >
              {label}
            </Box>
            <Dropdown
              flex={!w && 1}
              width={w}
              placeholder="選擇地區"
              name={name}
              options={areaData}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          </Flex>
        )
      }}
    </Data>
  )
}

AreaDropdown.defaultpProps = {
  name: 'area',
}

AreaDropdown.Field = ({ name, ...props }) => (
  <Field name={name}>
    {({ field }) => (
      <AreaDropdown
        {...props}
        {...field}
      />
    )}
  </Field>
)

AreaDropdown.FastField = ({ name, ...props }) => (
  <FastField name={name}>
    {({ field }) => (
      <AreaDropdown
        {...props}
        {...field}
      />
    )}
  </FastField>
)

export default AreaDropdown
