import React, { useState } from 'react'
import Truncate from 'react-truncate';
import sortBy from 'lodash/sortBy';

import Link from 'components/Link';
import Box from 'components/Box'
import Flex from 'components/Flex'
import AreaDropdown from 'components/AreaDropdown'
import Image from 'components/Image'
import Board from 'components/Board'
import { responsive } from 'components/ThemeProvider/theme';

import Data from 'contexts/data'
import useResponsive from 'contexts/mediaQuery/useResponsive'

import speaker from './speaker.png';
import backleftleaf from './backleftleaf.png';
import backrightleaf from './backrightleaf.png';
import frontleftleaf from './frontleftleaf.png';
import frontrightleaf from './frontrightleaf.png';

const TextContent = ({ lines, id, w, children, left, right, ...props }) => (
  <Box mt="1em" lineHeight="1.75" position="relative" zIndex={50} {...props}>
    <Truncate
      lines={lines}
      ellipsis={(
        <span>...
          <Box ml={responsive('2.25em', '3em')} width={w} />
        </span>
      )}
    >
      {children}
    </Truncate>
    <Box
      position="absolute"
      left={left}
      right={right || 0}
      bottom="0"
      bg="purple"
      px="0.875em"
      py={responsive('0.125em', '0.25em')}
      color="white"
      to={`/news/${id}`}
      lineHeight="1.25"
    >
      閱讀更多
    </Box>
  </Box>
)

const NewsLayout = ({ path, i, ...props }) => {
  const { isMobile } = useResponsive()
  const [nowArea, changeArea] = useState()
  return (
    <Box>
      <Box boxShadow="1px 3px 4px #C8C8C8" bg="white" pb="1em" pt={responsive('1em', '3.125em')} px={responsive('0.75em', '3.125em')}>
        {Boolean(i) && (
          <AreaDropdown
            zIndex={99}
            onChange={(e) => changeArea(e.target.value)}
            value={nowArea}
            w={responsive('70%', '35%')}
            label="地區查詢"
          />
        )}
      </Box>
      <Data path={nowArea ? `org/${nowArea}/news` : path} key={nowArea}>
        {(data) => data && ((
          <Box
            position="relative"
            {...props}
          >
            {Boolean(data.length) && !isMobile && (
              <>
                <Box position="absolute" right="0" bottom="0" transform="translateX(60%)">
                  <Image src={backrightleaf} alt="裝飾的樹葉圖樣" />
                </Box>
                <Box position="absolute" left="0" bottom="0.5em" transform="translateX(-53%)">
                  <Image src={backleftleaf} alt="裝飾的樹葉圖樣" />
                </Box>
              </>
            )}
            <Board noRadius pt="0em" minHeight={responsive('auto', '25em')}>
              {sortBy(data.slice(0, 6), (d) => d.startDate).reverse().map(({ subject, content, startDate, id }, i) => (
                <Box key={i}>
                  <Flex pt="1.875em" borderBottom={responsive('1px solid', 'none')} borderColor="lightPurple">
                    <Box width="3.75em" mr="1.375em">
                      <Image src={speaker} alt="大聲公的圖樣" />
                    </Box>
                    <Box as={(p) => <Link color="black" {...p} />} to={`/news/view?id=${id}`} flex={1}>
                      <Flex
                        pb="0.625em"
                        borderColor="lightPurple"
                        borderBottom={responsive('none', '1px solid')}
                        alignItems={responsive('auto', 'center')}
                        justifyContent={responsive('center', 'space-between')}
                        flexDirection={responsive('column-reverse', 'row')}
                      >
                        <Box fontWeight="bold" fontSize={responsive('1.125em', '1.375em')} color="darkPurple">{subject}</Box>
                        <Box fontSize={responsive('1.125em', '1.25em')}>{startDate.replace(/[-]/g, '/')}</Box>
                      </Flex>
                      {!isMobile && (
                        <TextContent lines={2} w="5em" id={id}>{content}</TextContent>
                      )}
                    </Box>
                  </Flex>
                  {isMobile && (
                    <TextContent
                      lines={5}
                      w="8em"
                      id={id}
                    >{content}</TextContent>
                  )}
                </Box>
              ))}
            </Board>
            {Boolean(data.length) && !isMobile && (
              <>
                <Box position="absolute" right="0" bottom="0" transform="translate(60%, 1em)">
                  <Image src={frontrightleaf} alt="裝飾的樹葉圖樣" />
                </Box>
                <Box position="absolute" left="0" bottom="0" transform="translate(-55%, 1em)">
                  <Image src={frontleftleaf} alt="裝飾的樹葉圖樣" />
                </Box>
              </>
            )}
          </Box>
        ))}
      </Data>
    </Box>
  )
}

export default NewsLayout
